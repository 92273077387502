<template>
  <div class="wrapper">
    <div class="top">
      <div class="text-wrapper-2">
        <p class="l">
          <span>发行编号：</span>
          <span>{{
            issueDetail.taIssueRegistSimpleDTO.issueNumber || "-"
          }}</span>
        </p>
        <p class="r">
          <span>发行类型：</span>
          <span>{{
            issueDetail.taIssueRegistSimpleDTO.type == "1" ? "自营发行" : "-"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="l">
          <span>发行方：</span>
          <span>{{ issueDetail.issuerName || "-" }}</span>
        </p>
        <p class="r">
          <span>艺术品ID：</span>
          <span class="max">{{
            issueDetail.taIssueRegistSimpleDTO.nftCode || "-"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="l">
          <span>发行数量：</span>
          <span
            >{{ issueDetail.taIssueRegistSimpleDTO.totalCount || "-" }}个</span
          >
        </p>
        <p class="r">
          <span>生产数量：</span>
          <span>{{ issueDetail.createCount || "-" }}个</span>
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="l">
          <span>流转方式：</span>
          <span>{{
            issueDetail.taIssueRegistSimpleDTO.tradeType == "1"
              ? "转让"
              : "受让"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="l">
          <span>产品编号：</span>
          <span>{{ issueDetail.productDetailDTO.detailNo || "-" }}</span>
        </p>
        <p class="r">
          <span>证书编号：</span>
          <span>{{ issueDetail.productDetailDTO.certificateNo || "-" }}</span>
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="l">
          <span>特征码哈希：</span>
          <span>{{ issueDetail.productDetailDTO.chainCode || "-" }}</span>
        </p>
      </div>
      <!-- <div class="text-wrapper-2">
        <p class="l max">
          <span>发行说明：</span>
          <span class="max">
            发行说明发行说明发行说明发行说明发行说明发行说明发行说明
          </span>
        </p>
      </div> -->
      <!-- <div class="text-wrapper-2">
        <div class="l">
          <span>防伪特征图片：</span>
          <div class="img-wrapper">
            <div
              class="block"
              v-for="(item, index) of issueDetail.productDetailDTO.pictureFileList"
              :key="index"
            >
              <el-image
                style="width: 100%; height: 100%"
                :src="item.path"
                fit="cover"
              ></el-image>
            </div>
          </div>
        </div>
        <div class="l">
          <p class="w">
            <span>特征码哈希：</span>
            <span>{{ issueDetail.productDetailDTO.chainCode || "-" }}</span>
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    issueDetail: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 40px;
  .top {
    width: 100%;
    height: auto;
    padding: 10px 0;
    border-bottom: 1px solid #e4e7ed;
    .text-wrapper-2 {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .l {
        width: 50%;
        overflow: hidden;
        .img-wrapper {
          width: 70%;
          display: flex;
          flex-wrap: wrap;
          overflow: hidden;
          float: left;
          .block {
            width: 100px;
            height: 100px;
            border-radius: 4px;
            overflow: hidden;
            margin-right: 15px;
            margin-bottom: 15px;
            float: left;
          }
        }
        &.max {
          width: 80%;
        }
        span {
          font-size: 16px;
          display: inline-block;
          &:nth-child(1) {
            color: #262626;
            float: left;
          }
          &:nth-child(2) {
            color: #909399;
            float: left;
            max-width: 70%;
            word-break: break-all;
            &.max {
              max-width: 90%;
            }
          }
        }
      }
      .r {
        width: 50%;
        overflow: hidden;
        .w {
          width: 100%;
          overflow: hidden;
          margin-bottom: 15px;
        }
        .block {
          width: 100px;
          height: 100px;
          float: left;
        }
        span {
          font-size: 14px;
          display: inline-block;
          &:nth-child(1) {
            color: #262626;
            float: left;
          }
          &:nth-child(2) {
            color: #909399;
            float: left;
            max-width: 70%;
            word-break: break-all;
          }
        }
      }
    }
    .part {
      width: 25%;
      display: flex;
    }
    .item {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #606266;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .label-wrapper {
      width: 100px;
      flex-grow: 0;
      flex-shrink: 0;
      .item {
        font-size: 14px;
        font-weight: 400;
        color: #909399;

        text-align: right;
      }
    }
  }
}
</style>