<template>
  <div class="wrapper">
    <div class="top">
      <div class="text-wrapper-2">
        <p class="l">
          <span>产品名称：</span>
          <span>{{ issueDetail.name || "-" }}</span>
        </p>
        <p class="r">
          <span>产品类目：</span>
          <span>{{ issueDetail.typeName || issueDetail.type }}</span>
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="r">
          <span>产品简称：</span>
          <span>{{ issueDetail.shortName || "-" }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>描述：</span>
          <span class="max">{{ issueDetail.introduce || "-" }}</span>
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="l">
          <span>设计师：</span>
          <span
            v-for="(item, index) of issueDetail.designerList"
            :key="index"
            >{{ item.nickName || "-" }}</span
          >
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="l">
          <span>尺寸：</span>
          <span>{{ issueDetail.size || "-" }}</span>
        </p>
        <p class="r">
          <span>形状：</span>
          <span>{{ issueDetail.shape || "-" }}</span>
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="l">
          <span>工艺：</span>
          <span>{{ issueDetail.technology || "-" }}</span>
        </p>
        <p class="r">
          <span>材质：</span>
          <span>{{ issueDetail.material || "-" }}</span>
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="l">
          <span>底座尺寸：</span>
          <span>{{ issueDetail.pedestalSize || "-" }}</span>
        </p>
        <p class="r">
          <span>底座材质：</span>
          <span>{{ issueDetail.pedestalMaterial || "-" }}</span>
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="l">
          <span>外包装尺寸：</span>
          <span>{{ issueDetail.outerSize || "-" }}</span>
        </p>
        <p class="r">
          <span>外包装材质：</span>
          <span>{{ issueDetail.outerMaterial || "-" }}</span>
        </p>
      </div>
    </div>
    <div class="img-wrapper">
      <div v-html="getRichText"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    issueDetail: {
      type: Object,
    },
  },
  computed: {
    getRichText() {
      if(this.issueDetail.productStory) {
        return this.issueDetail.productStory.replace(/<img/g, "<img style='width:100%;height:auto;'")
      } else {
        return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  .top {
    width: 100%;
    height: auto;
    padding: 10px 40px;
    border-bottom: 1px solid #e4e7ed;
    .text-wrapper-2 {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .l {
        width: 50%;
        overflow: hidden;
        &.max {
          width: 80%;
        }
        span {
          font-size: 16px;
          display: inline-block;
          &:nth-child(1) {
            color: #262626;
            float: left;
          }
          &:nth-child(2) {
            color: #909399;
            float: left;
            max-width: 70%;
            word-break: break-all;
            &.max {
              max-width: 90%;
            }
          }
        }
      }
      .r {
        width: 50%;
        overflow: hidden;
        span {
          font-size: 16px;
          display: inline-block;
          &:nth-child(1) {
            color: #262626;
            float: left;
          }
          &:nth-child(2) {
            color: #909399;
            float: left;
            max-width: 70%;
            word-break: break-all;
          }
        }
      }
    }
    .part {
      width: 25%;
      display: flex;
    }
    .item {
      height: 20px;
      line-height: 20px;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .label-wrapper {
      width: 100px;
      flex-grow: 0;
      flex-shrink: 0;
      .item {
        font-size: 16px;
        font-weight: 400;
        color: #909399;

        text-align: right;
      }
    }
  }
  .img-wrapper {
    padding-top: 30px;
    padding-bottom: 50px;
    img {
      width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>