<template>
  <div class="product-detail-wrapper" v-if="issueDetail.name">
    <div class="header-wrapper">
      <div class="wrapper">
        <div class="left">
          <el-carousel trigger="click" height="560px">
            <el-carousel-item v-for="(item, index) of swiperList" :key="index">
              <el-image
                style="width: 100%; height: 100%"
                :src="item.path"
                fit="cover"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
          <div v-if="issueDetail.modelScanList && issueDetail.modelScanList.length > 0" class="threed" @click="view3d">
            <img class="threed-icon" src="@/assets/images/threed.png" alt="3D展示">
            3D展示
          </div>
        </div>
        <div class="right">
          <p class="title">{{ issueDetail.name }}</p>
          <div class="content namecontent">
            <p>{{ issueDetail.typeName || issueDetail.type }}</p>
            <p v-if="issueDetail.taIssueRegistSimpleDTO">NFT名称：{{ issueDetail.taIssueRegistSimpleDTO.nftName }}</p>
            <p v-if="issueDetail.taIssueRegistSimpleDTO">NFT代码：{{ issueDetail.taIssueRegistSimpleDTO.nftCode }}</p>
          </div>
          <p class="title-1">产品描述：</p>
          <p class="content scroll">
            {{ issueDetail.introduce }}
          </p>
          <div class="text-wrapper-2">
            <p class="title-1">设计师：</p>
            <p>
              <span class="design-name">{{ getDesignList[0].join("、") }}</span>
              <el-popover
                placement="top-start"
                v-if="getDesignList[1].length > 0"
                width="248"
                trigger="hover"
                :content="getDesignList[1].join(' ')"
              >
                <span slot="reference" style="color: #ff9100"> 查看全部</span>
              </el-popover>
            </p>
          </div>
          <div class="text-wrapper">
            <div class="text type-1">
              <p>物权所有人：</p>
              <p class="one-ellipsis">{{ issueDetail.owner || "-" }}</p>
            </div>
            <div class="text type-1">
              <p>版权方：</p>
              <p class="one-ellipsis">{{ issueDetail.copyrightName }}</p>
            </div>

            <div class="text type-1">
              <p>生产方：</p>
              <p class="one-ellipsis">{{ issueDetail.createUserName }}</p>
            </div>
            <div class="text type-1">
              <p>发行方：</p>
              <p class="one-ellipsis">{{ issueDetail.issuerName }}</p>
            </div>
          </div>
          <el-button
            v-if="
              detailNumber &&
              issueDetail.productDetailDTO &&
              issueDetail.productDetailDTO.assetsStatus == 1
            "
            @click="exchangeFn"
            style="width: 280px; height: 40px"
            type="primary"
            round
          >
            转 让
          </el-button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="tabs">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="pro-base-info">
            <proBaseInfo :issueDetail="issueDetail"></proBaseInfo>
          </el-tab-pane>
          <el-tab-pane label="生产信息" name="pro-info">
            <proInfo :issueDetail="issueDetail"></proInfo>
          </el-tab-pane>
          <el-tab-pane v-if="detailNumber" label="发行信息" name="issue-info">
            <issueInfo :issueDetail="issueDetail"></issueInfo>
          </el-tab-pane>
          <el-tab-pane
            v-if="detailNumber"
            label="流转记录"
            name="exchange-record"
          >
            <exchangeRecord :issueDetail="issueDetail"></exchangeRecord>
          </el-tab-pane>
          <el-tab-pane v-if="detailNumber" label="链上溯源" name="on-chain">
            <onChain :issueDetail="issueDetail"></onChain>
          </el-tab-pane>
          <!-- <el-tab-pane label="区块链浏览器" name="block-chain">
            <blockChain></blockChain>
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
    <el-dialog title="资产转让" :visible.sync="exchangeDialog" width="500px">
      <div class="exchange-wrapper">
        <p
          class="hint"
          v-if="
            issueDetail.taIssueRegistSimpleDTO &&
            issueDetail.taIssueRegistSimpleDTO.assigneeType === 2
          "
        >
          <i class="el-icon-warning"></i>
          <span>
            该产品的受让方式为【直接到账】，确认转让后，无需受让者确认资产将直接转入受让者区块链地址中，请谨慎操作
          </span>
        </p>
        <div class="wrapper">
          <span>产品名称:</span>
          <span>{{ issueDetail.name }}</span>
        </div>
        <div class="wrapper">
          <span>艺术品ID:</span>
          <span v-if="issueDetail.taIssueRegistSimpleDTO">{{
            issueDetail.taIssueRegistSimpleDTO.nftCode
          }}</span>
        </div>
        <div class="wrapper">
          <span>数量:</span>
          <span>1</span>
        </div>
        <div class="wrapper" v-if="issueDetail.productDetailDTO">
          <span>产品编号:</span>
          <span>{{ issueDetail.productDetailDTO.detailNo }}</span>
        </div>
        <div class="input-wrapper">
          <div class="input">
            <span> 受让者区块链地址: </span>
            <el-input
              style="width: 280px"
              placeholder="请输入受让者区块链地址"
              v-model="input_address"
              clearable
            >
            </el-input>
          </div>
          <div class="input">
            <span> 备注: </span>
            <el-input
              style="width: 280px"
              type="textarea"
              placeholder="您可以输入祝福语、寄语等"
              v-model="input_remark"
              clearable
            >
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exchangeDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmFn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getProductInfo, issProductInfo, batchAdd } from "@/api/index";
import proBaseInfo from "./component/proBaseInfo.vue";
import proInfo from "./component/proInfo.vue";
import issueInfo from "./component/issueInfo.vue";
import exchangeRecord from "./component/exchangeRecord.vue";
import onChain from "./component/onChain.vue";
// import blockChain from "./component/blockChain.vue";
export default {
  name: "Product",
  components: {
    proBaseInfo,
    proInfo,
    issueInfo,
    exchangeRecord,
    onChain,
  },
  data() {
    return {
      input_remark: "",
      input_address: "",
      exchangeDialog: false,
      swiperList: [],
      productNumber: this.$route.query.productNumber,
      productDetailNumber: this.$route.query.productDetailNumber,
      activeName: "pro-base-info",
      issueDetail: {},
      menuList: [
        { path: "pro-base-info", text: "基本信息" },
        { path: "pro-info", text: "生产信息" },
        { path: "issue-info", text: "发行信息" },
        { path: "exchange-record", text: "流转记录" },
        { path: "on-chain", text: "链上溯源" },
        { path: "block-chain", text: "区块链浏览器" },
      ],
      detailNumber: null,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.productNumber = this.$route.query.productNumber || null;
      this.detailNumber = this.$route.query.productDetailNumber || null;
      if (this.productNumber && this.detailNumber) {
        this.getDetails();
      }
      if (this.productNumber && !this.detailNumber) {
        this.issProductInfo();
      }
    },
    async issProductInfo() {
      let response = await issProductInfo(this.productNumber);
      if (response.code !== 200) {
        return false;
      }
      this.issueDetail = response.data;
      this.swiperList = response.data.productPictureFileList;
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
    async confirmFn() {
      if (this.input_address.trim() === "") {
        this.$message.warning("请输入受让者区块链地址");
        return false;
      }
      let walletAddress = this.$store.state.userInfo.walletAddress;
      let obj = {
        sendAddress: walletAddress,
        detailNumberList: [this.productDetailNumber],
        receiverAddress: this.input_address,
        sendRemark: this.input_remark,
      };
      let data = await batchAdd(obj);
      if (data.code != "200") {
        this.$message.error(data.msg);
        return false;
      }
      if (data.code == "200") {
        this.$message.success("操作成功");
        this.initData();
      }
      this.exchangeDialog = false;
    },
    exchangeFn() {
      this.exchangeDialog = true;
    },
    async getDetails() {
      let response = await getProductInfo(
        this.productNumber,
        this.productDetailNumber
      );
      if (response.code !== 200) {
        this.$message.error(response.msg);
        return false;
      }
      this.issueDetail = response.data;
      this.swiperList = response.data.productPictureFileList;
    },
    handleClick() {},
    view3d() {
      let routeUrl = this.$router.resolve({
        path: "/threed",
        query: {productNumber:this.productNumber}
      });
     window.open(routeUrl .href, '_blank');
    },
  },
  computed: {
    getDesignList() {
      let list = this.issueDetail.designerList.map((item) => {
        return item.nickName;
      });
      if (list) {
        if (list.length > 5) {
          return [list.slice(0, 5), list];
        } else {
          return [list, []];
        }
      } else {
        return [[], []];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-detail-wrapper {
  width: 100%;
  height: auto;
  /deep/.el-dialog__body {
    padding: 0 0 30px;
  }
  .exchange-wrapper {
    .input-wrapper {
      border-top: 1px solid #f2f6fc;
      padding-top: 22px;
      .input {
        padding: 0 35px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;
        span {
          width: 120px;
          color: #909399;
          text-align: right;
        }
      }
    }
    .wrapper {
      display: flex;
      align-items: center;
      margin-top: 18px;
      margin-bottom: 18px;
      padding: 0 24px;
      span {
        font-size: 14px;
        &:nth-child(1) {
          width: 120px;
          color: #909399;
          text-align: right;
          margin-right: 10px;
        }
        &:nth-child(2) {
          color: #262626;
        }
      }
    }
    .hint {
      background-color: #fff1f0;
      height: 50px;
      display: flex;
      align-items: flex-start;
      color: #ff4d4f;
      padding: 8px 24px;
      i {
        margin-top: 3px;
      }
      span {
        margin-left: 5px;
      }
    }
  }
  .container {
    width: 1200px;
    min-height: 500px;
    background: #ffffff;
    border-radius: 10px 10px 0px 0px;
    margin: 30px auto 0;
    .tabs {
      padding: 0 0 30px;
      /deep/.el-tabs__header {
        background-color: #fafafa !important;
        border-radius: 10px 10px 0px 0px;
      }
      /deep/.el-tabs__item {
        height: 60px !important;
        line-height: 60px !important;
        font-size: 16px !important;
        &:nth-child(2) {
          padding-left: 20px !important;
        }
      }
    }
  }
  .header-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 30px;
    padding-top: 30px;
    .wrapper {
      width: 1200px;
      height: auto;
      margin: 0 auto;
      display: flex;
      box-sizing: border-box;
      .right {
        // width: 550px;
        width: 100%;
        padding-top: 24px;
        height: auto;
        .text-wrapper-2 {
          margin-bottom: 24px;
        }
        .design-name {
          color: #4e4e4e;
          font-weight: 300;
          font-size: 16px;
        }
        .text-wrapper {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 40px;
          .text {
            width: 50%;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            p {
              &:nth-child(1) {
                font-size: 18px;
                color: #262626;
                font-weight: 500;
                margin-bottom: 5px;
              }
              &:nth-child(2) {
                font-size: 16px;
                color: #4e4e4e;
                font-weight: 400;
                padding-right: 10px;
              }
            }
          }
        }
        .title-1 {
          font-size: 18px;
          font-weight: 600;
          color: #262626;
          margin-top: 24px;
          margin-bottom: 8px;
        }
        .content {
          font-size: 16px;
          color: #909399;
          font-weight: 400;
          
          &.scroll {
            width: 100%;
            max-height: 100px;
            margin-bottom: 24px;
            overflow-y: auto;
            color: #262626;
            font-weight: 300;
          }
        }
        .namecontent{
          display: flex;
          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &:nth-child(1) {
              width: 126px;
            }
            &:nth-child(2) {
              width: 234px;
            }
            &:nth-child(3) {
              width: 204px;
            }
          }
        }
        .title {
          color: #262626;
          font-size: 36px;
          font-weight: 600;
          margin-bottom: 8px;
        }
      }
      .left {
        width: 560px;
        height: 560px;
        border-radius: 16px;
        overflow: hidden;
        margin-right: 64px;
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
        }
        .gallery-top {
          height: 100%;
          width: 100%;
        }
        .swiper-container {
          --swiper-theme-color: #ffffff; /* 设置Swiper风格 */
          --swiper-navigation-size: 20px; /* 设置按钮大小 */
        }
        .gallery-thumbs {
          width: 100%;
          height: 20%;
          box-sizing: border-box;
          padding: 10px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          background-color: rgba(000, 000, 000, 0.7);
          .swiper-slide {
            opacity: 0.4;
          }
          .swiper-slide-thumb-active {
            opacity: 1;
          }
        }
      }
    }
  }
}
.threed{
  position: absolute;
  bottom: 63px;
  left: 50%;
  width: 126px;
  height: 40px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  margin-left: -63px;
  cursor: pointer;
}
.threed-icon{
  display: block;
  width: 18px!important;
  height: 18px!important;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 8px;
}

</style>
