<template>
  <div class="wrapper">
    <div class="block" v-if="chainOriginsData.productInfo">
      <p class="title-1">产品信息</p>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>上链信息：</span>
          <span class="max">{{
            chainOriginsData.productInfo.name || "-"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>文件HASH：</span>
          <span class="max">{{
            chainOriginsData.productInfo.shaHash || "-"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>链上地址：</span>
          <span class="max">{{
            chainOriginsData.productInfo.chainHash || "-"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>从：</span>
          <span class="max">{{
            chainOriginsData.productInfo.chainFrom || "-"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>到：</span>
          <span class="max">{{
            chainOriginsData.productInfo.chainTo || "-"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>所在区块：</span>
          <span class="max">{{
            chainOriginsData.productInfo.block || "-"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>上链时间：</span>
          <span class="max">{{
            chainOriginsData.productInfo.chainTime || "-"
          }}</span>
        </p>
      </div>
    </div>
    <div class="block">
      <p class="title-1">产品发行签名</p>
      <div
        class="text-wrapper-2 block1 max"
        v-for="(item, index) of chainOriginsData.signInfoList"
        :key="index"
      >
        <p class="l max">
          <span>{{ item.roleType }}：</span>
          <span class="max">{{ item.nickName || "-" }}</span>
        </p>
        <p class="l max">
          <span>公钥：</span>
          <span class="max">{{ item.publicKey || "-" }}</span>
        </p>
        <p class="l max">
          <span>签名HASH：</span>
          <span class="max">{{ item.signHash || "-" }}</span>
        </p>
        <p class="l max">
          <span>签名时间：</span>
          <span class="max">{{ item.signTime || "-" }}</span>
        </p>
      </div>
    </div>
    <div class="block" v-if="chainOriginsData.modelInfo">
      <p class="title-1">产品3D模型</p>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>3D模型名称：</span>
          <span class="max">{{ chainOriginsData.modelInfo.name || "-" }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>模型HASH：</span>
          <span class="max">{{
            chainOriginsData.modelInfo.shaHash || "-"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>链上地址：</span>
          <span class="max">{{
            chainOriginsData.modelInfo.chainHash || "-"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>从：</span>
          <span class="max">{{
            chainOriginsData.modelInfo.chainFrom || "-"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>到：</span>
          <span class="max">{{
            chainOriginsData.modelInfo.chainTo || "-"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>所在区块：</span>
          <span class="max">{{ chainOriginsData.modelInfo.block || "-" }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>上链时间：</span>
          <span class="max">{{
            chainOriginsData.modelInfo.chainTime || "-"
          }}</span>
        </p>
      </div>
    </div>
    <div class="block" v-if="chainOriginsData.productPictureInfo">
      <p class="title-1">产品图片</p>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>上链信息：</span>
          <span class="max">{{
            chainOriginsData.productPictureInfo.name
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>图片HASH：</span>
          <span class="max">{{
            chainOriginsData.productPictureInfo.shaHash || "-"
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>链上地址：</span>
          <span class="max">{{
            chainOriginsData.productPictureInfo.chainHash
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>从：</span>
          <span class="max">{{
            chainOriginsData.productPictureInfo.chainFrom
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>到：</span>
          <span class="max">{{
            chainOriginsData.productPictureInfo.chainTo
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>所在区块：</span>
          <span class="max">{{
            chainOriginsData.productPictureInfo.block
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>上链时间：</span>
          <span class="max">{{
            chainOriginsData.productPictureInfo.chainTime
          }}</span>
        </p>
      </div>
    </div>
    <div
      class="block"
      v-if="
        chainOriginsData.productFeatureInfo &&
        !chainOriginsData.productFeatureInfo.unChain
      "
    >
      <p class="title-1">产品特征</p>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>产品编号：</span>
          <span class="max">{{
            chainOriginsData.productFeatureInfo.detailNo
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>特征码HASH：</span>
          <span class="max">{{
            chainOriginsData.productFeatureInfo.shaHash
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>链上地址：</span>
          <span class="max">{{
            chainOriginsData.productFeatureInfo.chainHash
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>从：</span>
          <span class="max">{{
            chainOriginsData.productFeatureInfo.chainFrom
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>到：</span>
          <span class="max">{{
            chainOriginsData.productFeatureInfo.chainTo
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>所在区块：</span>
          <span class="max">{{
            chainOriginsData.productFeatureInfo.block
          }}</span>
        </p>
      </div>
      <div class="text-wrapper-2 max">
        <p class="l max">
          <span>上链时间：</span>
          <span class="max">{{
            chainOriginsData.productFeatureInfo.chainTime
          }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { chainOriginsDetails } from "@/api/index.js";
export default {
  props: {
    issueDetail: {
      type: Object,
    },
  },
  data() {
    return {
      chainOriginsData: {},
      productNumber: this.$route.query.productNumber,
      productDetailNumber: this.$route.query.productDetailNumber,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let data = await chainOriginsDetails(
        this.productNumber,
        this.productDetailNumber
      );
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return false;
      }
      this.chainOriginsData = data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  .block {
    border-bottom: 1px solid #f5f5f5;
    padding: 10px;
    .title-1 {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 24px;
    }

    .text-wrapper-2 {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      &.block1 {
        display: block;
        .l {
          margin-bottom: 8px;
          &.max {
            width: 100%;
          }
        }
        span {
          &:nth-child(2) {
            &.max {
              max-width: 100%;
            }
          }
        }
      }
      .l {
        width: 50%;
        overflow: hidden;
        &.max {
          width: 80%;
        }
        span {
          font-size: 16px;
          display: inline-block;
          &:nth-child(1) {
            width: 110px;
            text-align: right;
            color: #262626;
            float: left;
          }
          &:nth-child(2) {
            color: #909399;
            float: left;
            max-width: 70%;
            word-break: break-all;
            &.max {
              max-width: 90%;
            }
          }
        }
      }
      .r {
        width: 50%;
        overflow: hidden;
        span {
          font-size: 16px;
          display: inline-block;
          &:nth-child(1) {
            color: #262626;
            float: left;
          }
          &:nth-child(2) {
            color: #909399;
            float: left;
            max-width: 70%;
            word-break: break-all;
          }
        }
      }
    }
  }
}
</style>