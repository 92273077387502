<template>
  <div class="wrapper">
    <el-table
      :header-cell-style="{
        'background-color': '#F5F7FA',
        color: '#262626',
      }"
      :data="tableData"
      style="width: 80%"
    >
      <el-table-column prop="eventType" width="100" label="事件"> </el-table-column>
      <el-table-column prop="quality" align="right" width="80" label="数量"> </el-table-column>
      <el-table-column prop="sendName" label="发起方"> </el-table-column>
      <el-table-column prop="receiveName" label="接收方"> </el-table-column>
      <el-table-column prop="createTime" label="时间">
        <template slot-scope="scopes">
          <p>{{ scopes.row.createTime || "-" }}</p>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { transferDetails } from "@/api/index.js";
export default {
  props: {
    issueDetail: {
      type: Object,
    },
  },
  data() {
    return {
      list: [],
      productNumber: this.$route.query.productNumber,
      productDetailNumber: this.$route.query.productDetailNumber,
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let params = {
        pageNum: "1",
        pageSize: "30",
        detailNumber: this.productDetailNumber,
      };

      let data = await transferDetails(params);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return false;
      }
      this.tableData = data.data.list;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper{
  padding: 0 40px;
}
</style>