<template>
  <div class="wrapper">
    <div class="top">
      <div class="text-wrapper-2">
        <p class="l">
          <span>版权方：</span>
          <span>{{ issueDetail.copyrightName || "-" }}</span>
        </p>
        <p class="r">
          <span>版权方地址：</span>
          <span>{{ issueDetail.copyrightAddress || "-" }}</span>
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="r">
          <span>生产方：</span>
          <span>{{ issueDetail.createUserName || "-" }}</span>
        </p>
        <p class="r">
          <span>生产方地址：</span>
          <span class="max">{{ issueDetail.createAddress || "-" }}</span>
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="l">
          <span>生产批次号：</span>
          <span>{{ issueDetail.createNo || "-" }}</span>
        </p>
        <p class="r">
          <span>生产完成时间：</span>
          <span>{{ issueDetail.productTime || "-" }}</span>
        </p>
      </div>
      <div class="text-wrapper-2">
        <p class="l">
          <span>生产数量：</span>
          <span>{{ issueDetail.createCount || "-" }}个</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    issueDetail: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 40px;
  .top {
    width: 100%;
    height: auto;
    padding: 10px 0;
    // border-bottom: 1px solid #e4e7ed;
    .text-wrapper-2 {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .l {
        width: 50%;
        overflow: hidden;
        &.max {
          width: 80%;
        }
        span {
          font-size: 16px;
          display: inline-block;
          &:nth-child(1) {
            color: #262626;
            float: left;
          }
          &:nth-child(2) {
            color: #909399;
            float: left;
            max-width: 70%;
            word-break: break-all;
            &.max {
              max-width: 90%;
            }
          }
        }
      }
      .r {
        width: 50%;
        overflow: hidden;
        span {
          font-size: 16px;
          display: inline-block;
          &:nth-child(1) {
            color: #262626;
            float: left;
          }
          &:nth-child(2) {
            color: #909399;
            float: left;
            max-width: 70%;
            word-break: break-all;
          }
        }
      }
    }
    .part {
      width: 25%;
      display: flex;
    }
    .item {
      height: 20px;
      line-height: 20px;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .label-wrapper {
      width: 100px;
      flex-grow: 0;
      flex-shrink: 0;
      .item {
        font-size: 16px;
        font-weight: 400;
        color: #909399;

        text-align: right;
      }
    }
  }
  .img-wrapper {
    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>